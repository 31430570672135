import { useTranslation } from 'react-i18next';
import { Flex, Title } from '@mantine/core';
import { useAppStore } from 'stores/app';
import { LandscapeNavbar, PortraitNavbar } from 'layouts';

export const testIds = {
  title: 'flow-page-title',
};

export const FlowNavbar = () => {
  const { isLandscape } = useAppStore(['isLandscape']);
  const { t } = useTranslation();

  return isLandscape ? (
    <LandscapeNavbar
      leftSection={
        <Title order={5} fw={600} data-testid={testIds.title}>
          {t('flows.title')}
        </Title>
      }
    />
  ) : (
    <Flex direction='column' mb='lg'>
      <PortraitNavbar />
      <Title order={5} fw={600} data-testid={testIds.title}>
        {t('flows.title')}
      </Title>
    </Flex>
  );
};
