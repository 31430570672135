import { ContainerId, Focus, GeneratedSource, SessionId } from '@flow/flow-backend-types';
import { create } from 'zustand';
import { devtools, DevtoolsOptions } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { containerStore } from 'stores/container';
import { focusApi } from 'stores/focus/focus.api';
import { flowStore } from 'stores/flow';
import { createStoreHook } from '@aiola/frontend';
import { useShallow } from 'zustand/react/shallow';
import { authStore } from 'stores/auth';

const devtoolsOptions: DevtoolsOptions = {
  name: 'focus',
  store: 'focus',
  enabled: process.env.NODE_ENV === 'development',
};

interface FocusState {
  focusData: Focus | undefined;
}

interface FocusActions {
  focusContainer: (containerId: ContainerId) => void;
  blurContainer: () => void;
  focusUiEvent: (eventId: string) => void;
  blurUiEvent: () => void;
  onFocusUpdate: (newFocus: Focus, sessionId?: SessionId) => void;
  reset: () => void;
}

const focusInitialState: FocusState = {
  focusData: undefined,
};

export const focusStore = create(
  devtools(
    immer<FocusState & FocusActions>((set, get) => ({
      ...focusInitialState,
      focusContainer: (containerId: string) => {
        const { currentExecutionId } = flowStore.getState();
        const { currentUser } = authStore.getState();
        const { containers } = containerStore.getState();
        const container = containers[containerId];
        if (currentUser && currentExecutionId) {
          const newFocus: Focus = {
            containerId,
            userId: currentUser?.userId,
            isDynamic: container?.isDynamic,
            typeTitle: container?.containerTypeId,
            generatedSource: GeneratedSource.UI,
            flowExecutionId: currentExecutionId,
          };
          set({ focusData: newFocus });
          focusApi.updateFocus(newFocus);
        }
      },
      blurContainer: () => {
        const { currentExecutionId } = flowStore.getState();
        const { closeContainerLog } = containerStore.getState();
        if (currentExecutionId) {
          set({ focusData: undefined });
          focusApi.removeFocus(currentExecutionId);
          closeContainerLog();
        }
      },
      focusUiEvent: (eventId: string) => {
        const currentFocus = get().focusData;
        if (currentFocus) {
          const newFocus: Focus = { ...currentFocus, eventId, generatedSource: GeneratedSource.UI };
          set({ focusData: newFocus });
          focusApi.updateFocus(newFocus);
        }
      },
      blurUiEvent: () => {
        const { focusData, focusContainer } = get();
        if (focusData?.eventId) {
          focusContainer(focusData?.containerId ?? '');
        }
      },
      // update focus from external event (i.e. socket event)
      onFocusUpdate: (newFocus, sessionId) => {
        const { sessionId: currentSessionId } = authStore.getState();
        const { currentExecutionId } = flowStore.getState();
        const { openedLogContainerId, openContainerLog, openContainerParents } = containerStore.getState();

        const isSameExecution = newFocus.flowExecutionId === currentExecutionId;
        const isSameSession = sessionId === currentSessionId;

        if (!isSameExecution || !isSameSession) return;

        const newFocusContainerId = newFocus.containerId;
        const currentFocus = get().focusData;
        const hasContainerChanged = newFocusContainerId && newFocusContainerId !== currentFocus?.containerId;

        if (hasContainerChanged) {
          openContainerParents(newFocusContainerId);
          if (openedLogContainerId) {
            openContainerLog(newFocusContainerId);
          }
        }

        set({ focusData: newFocus });
      },
      reset: () => set(focusInitialState),
    })),
    devtoolsOptions,
  ),
);

export const useFocusStore = createStoreHook<FocusState & FocusActions>({ store: focusStore, useShallow });
