import { Button, Box } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { ActionNavbar } from 'layouts';
import { useFlowStore } from 'stores/flow';
import { names, useSpy } from 'services/espionage';
import { ROUTES } from 'routes/routes.config';
import { useForceNavigate } from 'hooks/useForceNavigate';
import { IconChevronRight } from '@tabler/icons-react';
import { testIds } from '../../ReviewPage';

interface ReviewNavbarNavbarProps {
  executionId: string;
}

export const ReviewNavbar = ({ executionId }: ReviewNavbarNavbarProps) => {
  const navigate = useForceNavigate();
  const { t } = useTranslation();
  const { continueExecution } = useFlowStore(['continueExecution']);
  const { spyClick } = useSpy();

  const onClickNext = () => {
    spyClick(names.ReviewPage.Next);
    navigate(ROUTES.SUMMARY_INSPECTION(executionId));
  };

  const onClickPrevious = async () => {
    await continueExecution(executionId);
    spyClick(names.ReviewPage.Back);
    navigate(ROUTES.INSPECTION(executionId));
  };

  return (
    <Box className='shadow-md' mb='sm'>
      <ActionNavbar
        title={t('review.title')}
        rightSection={
          <Button
            variant='subtle'
            size='compact-lg'
            px={0}
            bg='transparent'
            rightSection={<IconChevronRight className='-ml-1.5' />}
            onClick={onClickNext}
            data-testid={testIds.finishInspection}
          >
            {t('review.submitBtn')}
          </Button>
        }
        onBack={onClickPrevious}
      />
    </Box>
  );
};
