import { UiEvent, UiEventId } from '@flow/flow-backend-types';
import { UiEventFilter } from './filters.types';

export const createUiEventFilters = (uiEvents: Record<UiEventId, UiEvent>): UiEventFilter[] =>
  Object.values(uiEvents)
    .filter((event) => event.isFilterable)
    .map((event) => ({
      uiEventId: event.id,
      title: event.title,
      values: 'eventValues' in event.elementData ? event.elementData.eventValues : [],
      hasValidations: event.hasValidations,
    }));
