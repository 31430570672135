import { useMemo } from 'react';
import { Group, Box } from '@mantine/core';

interface EventTitleProps {
  title?: string;
  isMandatory?: boolean;
}

export const testIds = { mandatoryEvent: 'mandatory-event' };

export const EventTitle = ({ title, isMandatory }: EventTitleProps) =>
  useMemo(
    () => (
      <Group gap={3}>
        {title}
        {isMandatory && <Box data-testid={testIds.mandatoryEvent}>*</Box>}
      </Group>
    ),
    [title, isMandatory],
  );
