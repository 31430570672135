import { useCallback, useEffect } from 'react';
import { names, useSpy } from 'services/espionage';
import { useVoiceStore } from 'stores/voice';
import { useAuthStore } from 'stores/auth';
import { useDocumentVisibility, useIsFirstRender } from '@mantine/hooks';
import { VoiceBarState } from '../VoiceBar.types';

export function useVoiceAction(state: VoiceBarState) {
  const { spyClick } = useSpy();
  const { changeVoiceState, updateStreamMetadata } = useVoiceStore(['changeVoiceState', 'updateStreamMetadata']);
  const { sessionId } = useAuthStore(['sessionId']);
  const visibility = useDocumentVisibility();
  const isFirstRender = useIsFirstRender();

  useEffect(() => {
    updateStreamMetadata({ flowSessionId: sessionId! });
  }, [sessionId]);

  useEffect(() => {
    if (!isFirstRender) changeVoiceState('close');
  }, [visibility]);

  return useCallback(() => {
    switch (state) {
      case 'active':
      case 'warning': {
        spyClick(names.VoiceWidget.Release);
        return changeVoiceState('close');
      }
      case 'standby': {
        spyClick(names.VoiceWidget.Activate);
        return changeVoiceState('open');
      }
      default: {
        spyClick(names.VoiceWidget.Activate);
        return changeVoiceState('open');
      }
    }
  }, [state]);
}
