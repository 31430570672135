import { Badge, Box, Title, Flex, UnstyledButton } from '@mantine/core';
import cn from 'classnames';
import { Container } from '@flow/flow-backend-types';
import { names, ToggleContainerGroupStatus, useSpy } from 'services/espionage';
import { useContainerStore } from 'stores/container';
import { useFilterStore } from 'stores/filters';
import { useEffect, useState } from 'react';
import { IconChevronDown } from '@tabler/icons-react';
import { HiddenContentCollapse } from 'components';
import { useDelayScroll } from 'hooks';
import { ContainerList } from '../ContainerList/ContainerList';
import classes from './ContainerParent.module.css';

const SINGLE_ITEM_ANIMATION_TIME = 120;
const ITEMS_ANIMATION_MULTIPLIER = 10;
const BASE_GRAY_SHADE = 3;
const SHADE_STEP = 2;
const EDGE_SHADE_LEVEL = 2;

interface ContainerListProps {
  container: Container;
  filteredContainersAmount?: number;
  level?: number;
  collapseContainerDetails?: boolean;
  displayContainerContent?: boolean;
}

export const testIds = {
  getParentCardId: (containerId: string) => `container-parent-card-${containerId}`,
  filteredContainersAmount: 'related-containers-amount',
};

export const ContainerParent = ({
  container,
  filteredContainersAmount,
  level = 0,
  collapseContainerDetails,
  displayContainerContent,
}: ContainerListProps) => {
  const { id: containerId, title, childrenIds } = container;
  const { openParentContainersMap, toggleParentContainerOpen } = useContainerStore([
    'openParentContainersMap',
    'toggleParentContainerOpen',
  ]);
  const isOpen = openParentContainersMap.has(containerId);
  const { uiEventFilterValues, globalFilters } = useFilterStore(['uiEventFilterValues', 'globalFilters']);
  const ref = useDelayScroll<HTMLDivElement>(isOpen);
  const [isBackgroundGrayedOut, setIsBackgroundGrayedOut] = useState(false);
  const { spyClick } = useSpy();
  const amountOfChildren = container.childrenIds?.length ?? 0;
  const collapseDuration = (amountOfChildren / ITEMS_ANIMATION_MULTIPLIER + 1) * SINGLE_ITEM_ANIMATION_TIME;
  const grayShade = BASE_GRAY_SHADE - level * SHADE_STEP;
  const isGlobalFiltersApplied = Object.values(globalFilters).some(Boolean);
  const isUiEventFiltersApplied = uiEventFilterValues.some(
    (value) => !!value.validationFilters.length || !!value.valueFilters.length,
  );
  const isFiltersApplied = isGlobalFiltersApplied || isUiEventFiltersApplied;
  const showCountRelatedContainersBadge = !!(filteredContainersAmount && isFiltersApplied);

  useEffect(() => {
    if (isOpen) {
      setIsBackgroundGrayedOut(level < EDGE_SHADE_LEVEL);
    }
  }, [isOpen]);

  const toggleContainer = () => {
    const toggleStatus = isOpen ? ToggleContainerGroupStatus.COLLAPSE : ToggleContainerGroupStatus.EXPAND;

    spyClick(names.ExecutionPage.ToggleContainerGroup, { containerId, toggleStatus });
    toggleParentContainerOpen(containerId);
  };

  const removeBackground = () => {
    if (!isOpen) setIsBackgroundGrayedOut(false);
  };

  return (
    <Box ref={ref} p={8} bg={isBackgroundGrayedOut ? `gray.${grayShade}` : 'white'} className={classes.boxContainer}>
      <UnstyledButton
        onClick={toggleContainer}
        w='100%'
        p='tn'
        py={0}
        pl='tiny'
        mih={56}
        className='flex items-center gap-2 justify-between'
        data-testid={testIds.getParentCardId(container.id)}
      >
        <Flex>
          <IconChevronDown className={cn(isOpen ? 'rotate-180' : 'rotate-0', 'transition-transform')} />
          <Title order={5} className='first-letter:uppercase'>
            {title}
          </Title>
        </Flex>
        {showCountRelatedContainersBadge && (
          <Badge data-testid={testIds.filteredContainersAmount} px={7} ml='sm' color='red.4' radius='xl'>
            {filteredContainersAmount}
          </Badge>
        )}
      </UnstyledButton>
      <HiddenContentCollapse open={isOpen} animationDuration={collapseDuration} animationFinished={removeBackground}>
        <ContainerList
          level={level + 1}
          containerIds={childrenIds ?? []}
          collapseContainerDetails={collapseContainerDetails}
          displayContainerContent={displayContainerContent}
        />
      </HiddenContentCollapse>
    </Box>
  );
};
