import { Drawer, Title } from '@mantine/core';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useContainerStore } from 'stores/container';
import { useDynamicContainers } from 'pages/InspectionPage/InspectionPage.hooks';
import { TemplateItem } from './TemplateItem';

export const testIds = {
  drawer: 'dynamicTemplateDrawer',
  overlay: 'dynamicTemplateDrawerOverlay',
  title: 'dynamicTemplateDrawerTitle',
};

interface DynamicTemplateDrawerProps {
  executionId: string;
}

export const DynamicTemplateDrawer = ({ executionId }: DynamicTemplateDrawerProps) => {
  const { createNewDynamicContainer } = useDynamicContainers(executionId);
  const { isTemplatesListOpen, containerTemplatesMap, closeTemplatesList } = useContainerStore([
    'isTemplatesListOpen',
    'closeTemplatesList',
    'containerTemplatesMap',
  ]);
  const { t } = useTranslation();

  const containerTypeIds = useMemo(() => Object.keys(containerTemplatesMap), [containerTemplatesMap]);

  const onClick = (templateId: string) => {
    createNewDynamicContainer(templateId);
    closeTemplatesList();
  };

  return (
    <Drawer.Root opened={isTemplatesListOpen} size={240} position='bottom' onClose={closeTemplatesList}>
      <Drawer.Overlay data-testid={testIds.overlay} />
      <Drawer.Content data-testid={testIds.drawer}>
        <Drawer.Header bg='gray.1' py='xs'>
          <Title order={5} c='gray.6' bg='gray.1' data-testid={testIds.title}>
            {t('inspection.dynamicContainersList.title')}
          </Title>
        </Drawer.Header>
        <Drawer.Body p={0}>
          {containerTypeIds.map((containerTypeId) => (
            <TemplateItem
              key={containerTypeId}
              id={containerTypeId}
              value={Object.values(containerTemplatesMap[containerTypeId])[0].typeTitle}
              onClick={() => onClick(containerTypeId)}
            />
          ))}
        </Drawer.Body>
      </Drawer.Content>
    </Drawer.Root>
  );
};
