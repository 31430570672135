import { Box, Divider, Flex } from '@mantine/core';
import { ActionNavbar } from 'layouts';
import { names, useSpy } from 'services/espionage';
import { ROUTES } from 'routes/routes.config';
import { useForceNavigate } from 'hooks';
import { FinishInspection } from '../FinishInspection/FinishInspection';
import { InspectionActionButtons } from '../InspectionActionButtons/InspectionActionButtons';

interface InspectionNavbarProps {
  executionId: string;
  flowName?: string;
}

export const InspectionNavbar = ({ flowName, executionId }: InspectionNavbarProps) => {
  const { spyClick } = useSpy();
  const navigate = useForceNavigate();

  const onBack = () => {
    spyClick(names.ExecutionPage.Back);
    navigate(ROUTES.FLOWS);
  };

  return (
    <Flex direction='column'>
      <Box className='shadow-md'>
        <ActionNavbar title={flowName} rightSection={<FinishInspection executionId={executionId} />} onBack={onBack} />
        <Divider />
        <InspectionActionButtons />
      </Box>
    </Flex>
  );
};
