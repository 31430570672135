import { ActionIcon } from '@mantine/core';
import { IconChevronDown, IconMessageFilled } from '@tabler/icons-react';

interface VoiceBarToggleProps {
  opened: boolean;
  onClick: () => void;
}

export const testIds = {
  toggle: 'voice-bar-toggle-button',
};

export const VoiceBarToggle = ({ opened, onClick }: VoiceBarToggleProps) => (
  <ActionIcon color='black' variant='light' radius='xl' size='xl' onClick={onClick} data-testid={testIds.toggle}>
    {opened ? <IconChevronDown /> : <IconMessageFilled />}
  </ActionIcon>
);
