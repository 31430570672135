import { Flex, Badge, ActionIcon } from '@mantine/core';
import { IconFilter, IconPlus } from '@tabler/icons-react';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useFilterStore } from 'stores/filters';
import { names, NewDynamicContainerLocation, useSpy } from 'services/espionage';
import { useContainerStore } from 'stores/container';
import { ExecutionRouteParams } from 'routes/routes.config';
import { useDynamicContainers } from 'pages/InspectionPage/InspectionPage.hooks';
import { useAppStore } from 'stores/app';

export const testIds = {
  createDynamicContainerBtn: 'create-dynamic-container-btn',
  filterBtn: 'filter-btn',
  filterCounter: 'filter-counter',
};

export const InspectionActionButtons = () => {
  const { executionId } = useParams() as ExecutionRouteParams;
  const { inspectionDataLoading } = useAppStore(['inspectionDataLoading']);
  const { uiEventFilterValues, globalFilters, openFilters } = useFilterStore([
    'openFilters',
    'globalFilters',
    'uiEventFilterValues',
  ]);
  const { openTemplatesList } = useContainerStore(['openTemplatesList']);
  const { isDynamicContainers, hasMultipleDynamicContainers, createNewDynamicContainer } =
    useDynamicContainers(executionId);
  const filtersCount = useMemo(
    () =>
      uiEventFilterValues.reduce((acc, data) => acc + (data.valueFilters.length + data.validationFilters.length), 0) +
      Object.values(globalFilters).filter(Boolean).length,
    [uiEventFilterValues, globalFilters],
  );
  const { spyClick } = useSpy();

  const onClickFilter = () => {
    spyClick(names.ExecutionPage.OpenEventFilter);
    openFilters();
  };

  const onClickNewDynamicContainer = () => {
    spyClick(names.ExecutionPage.NewDynamicContainer, NewDynamicContainerLocation.BUTTON);
    if (hasMultipleDynamicContainers) openTemplatesList();
    else createNewDynamicContainer();
  };

  const displayCreateDynamicContainer = isDynamicContainers && !inspectionDataLoading;

  return (
    <Flex className='shadow-md' align='center' justify='space-between' p='sm'>
      <Flex align='center' gap='lg'>
        <ActionIcon
          className='overflow-visible'
          display='flex'
          pos='relative'
          color='black'
          variant='transparent'
          onClick={onClickFilter}
          data-testid={testIds.filterBtn}
        >
          <IconFilter />
          {!!filtersCount && (
            <Badge
              pos='absolute'
              right={-5}
              bottom={-4}
              color='green.7'
              px={5}
              size='xs'
              radius='xl'
              data-testid={testIds.filterCounter}
            >
              {filtersCount}
            </Badge>
          )}
        </ActionIcon>
      </Flex>
      {displayCreateDynamicContainer && (
        <IconPlus onClick={onClickNewDynamicContainer} data-testid={testIds.createDynamicContainerBtn} />
      )}
    </Flex>
  );
};
