import { UiEventId, ValueItem } from '@flow/flow-backend-types';

export enum ValidationFilter {
  Valid = 'valid',
  Invalid = 'invalid',
  Checked = 'checked',
  Unchecked = 'unchecked',
}

export interface GlobalFilters {
  /** Filter containers with mandatory events that are missing a value. */
  missingMandatory: boolean;
  /** Filter containers with events that are out of defined bounds. */
  outOfBounds: boolean;
}

export interface UiEventFilter {
  uiEventId: UiEventId;
  title?: string;
  values: ValueItem[];
  hasValidations: boolean;
}

export interface UiEventFilterValue {
  uiEventId: UiEventId;
  valueFilters: string[];
  validationFilters: ValidationFilter[];
}
