import { Box, Flex, Title, Text } from '@mantine/core';
import { useMemo } from 'react';
import { RenderEventValue } from 'pages/InspectionPage/components/RenderEvent/RenderEventValue';
import { Container, UiEvent } from '@flow/flow-backend-types';
import { useCEBridges } from 'stores/uiEvent';

export interface ContainerItemSummaryProps {
  container: Container;
  uiEvent: UiEvent | undefined;
  notApplicable?: boolean;
}

export const testIds = {
  summaryTestIdRegex: /container-summary-summary/,
  summaryContainer: (containerId: string) => `container-summary-summary-${containerId}`,
  summaryTitle: (containerId: string) => `container-summary-title-${containerId}`,
  summaryMandatory: 'container-summary-mandatory',
};

export const ContainerItemSummary = ({ container, uiEvent, notApplicable }: ContainerItemSummaryProps) => {
  const ceBridges = useCEBridges(container.id);
  const hasMandatoryEvents = useMemo(() => ceBridges.some(({ isMandatory }) => isMandatory), [container.id]);
  return (
    <Flex mih={32} justify='space-between' align='center' h='100%' data-testid={testIds.summaryContainer(container.id)}>
      <Title order={5} fw={400} data-testid={testIds.summaryTitle(container.id)}>
        {container.title}
        {hasMandatoryEvents && (
          <Text pl={4} component='span' data-testid={testIds.summaryMandatory}>
            *
          </Text>
        )}
      </Title>
      <Box w={80} className='shrink-0'>
        <RenderEventValue notApplicable={notApplicable} containerId={container.id} uiEvent={uiEvent} />
      </Box>
    </Flex>
  );
};
