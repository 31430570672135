import { Chip, Flex } from '@mantine/core';
import { UiEventId } from '@flow/flow-backend-types';
import { ValidationFilter } from 'stores/filters';
import { useTranslation } from 'react-i18next';
import { FilterItem } from '../../FilterItem/FilterItem';

export const testIds = {
  getEventValidationFilter: (groupId: string) => `event-Validation-filter-${groupId}`,
  getEventValidationFilterItem: (filterId: ValidationFilter) => `event-Validation-filter-item-${filterId}`,
  filterRegex: /^event-Validation-filter-item/,
};

interface EventValidationFilterProps {
  uiEventId: UiEventId;
  eventHasValidations: boolean;
  validationFilterValues: ValidationFilter[];
  onFilterChanged: (values: ValidationFilter[]) => void;
}

const STATUS_OPTIONS = [ValidationFilter.Checked, ValidationFilter.Unchecked];
const VALIDATION_OPTIONS = [ValidationFilter.Valid, ValidationFilter.Invalid];

export const EventValidationFilter = ({
  uiEventId,
  eventHasValidations,
  validationFilterValues,
  onFilterChanged,
}: EventValidationFilterProps) => {
  const { t } = useTranslation();

  const eventValidationOptions = eventHasValidations ? [...VALIDATION_OPTIONS, ...STATUS_OPTIONS] : STATUS_OPTIONS;

  return (
    <Flex gap='sm' wrap='wrap' data-testid={testIds.getEventValidationFilter(uiEventId)}>
      <Chip.Group
        multiple
        value={validationFilterValues}
        onChange={(values) => onFilterChanged(values as ValidationFilter[])}
      >
        {eventValidationOptions.map((value) => (
          <FilterItem key={value} value={value} data-testid={testIds.getEventValidationFilterItem(value)}>
            {t(`inspection.filter.validationFilter.${value}`)}
          </FilterItem>
        ))}
      </Chip.Group>
    </Flex>
  );
};
