import { useDebouncedValue } from '@mantine/hooks';
import { useEffect } from 'react';
import { useAppStore } from 'stores/app';
import { useVoiceStore } from 'stores/voice';

export function usePause() {
  const { changeVoiceState } = useVoiceStore(['changeVoiceState']);
  const { distractions } = useAppStore(['distractions']);
  const [debouncedDistractionCount] = useDebouncedValue(distractions.size, 500);

  useEffect(() => {
    if (debouncedDistractionCount) changeVoiceState('pause');
    else changeVoiceState('resume');
  }, [debouncedDistractionCount]);
}
