import { createStoreHook } from '@aiola/frontend';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { useShallow } from 'zustand/react/shallow';
import { devtools, DevtoolsOptions } from 'zustand/middleware';
import { focusStore } from 'stores/focus';
import { UIEventsMap } from 'stores/uiEvent';
import { UiEventFilter, UiEventFilterValue, GlobalFilters } from './filters.types';
import { createUiEventFilters } from './filters.utils';

const devtoolsOptions: DevtoolsOptions = {
  name: 'filters',
  store: 'filters',
  enabled: process.env.NODE_ENV === 'development',
};

interface FiltersState {
  isFiltersOpen: boolean;
  globalFilters: GlobalFilters;
  uiEventFilterValues: UiEventFilterValue[];
  uiEventFilters: UiEventFilter[];
}

interface FiltersActions {
  openFilters: () => void;
  closeFilters: () => void;
  setUiEventFilters: (uiEvents: UIEventsMap) => void;
  setGlobalFilters: (filters: Partial<GlobalFilters>) => void;
  setUiEventFilterValues: (values: UiEventFilterValue[]) => void;
  reset: () => void;
}

const filtersInitialState: FiltersState = {
  isFiltersOpen: false,
  uiEventFilterValues: [],
  uiEventFilters: [],
  globalFilters: {
    missingMandatory: false,
    outOfBounds: false,
  },
};

export const filterStore = create(
  devtools(
    immer<FiltersState & FiltersActions>((set) => ({
      ...filtersInitialState,
      setUiEventFilters: (uiEvents) => {
        const uiEventFilters = createUiEventFilters(uiEvents);
        set({ uiEventFilters });
      },
      setGlobalFilters: (filters = {}) => {
        set((state) => ({
          globalFilters: { ...state.globalFilters, ...filters },
        }));
        focusStore.getState().blurContainer();
      },
      setUiEventFilterValues: (values) => {
        set({ uiEventFilterValues: values });
        focusStore.getState().blurContainer();
      },
      openFilters: () => set({ isFiltersOpen: true }),
      closeFilters: () => set({ isFiltersOpen: false }),
      reset: () => {
        set(filtersInitialState);
      },
    })),
    devtoolsOptions,
  ),
);

export const useFilterStore = createStoreHook({ store: filterStore, useShallow });
