import { ScrollArea } from '@mantine/core';
import { VoiceBar } from '..';
import { InspectionListContent } from '../InspectionListContent/InspectionListContent';

export const testIds = {
  inspectionMainContent: 'inspection-main-content',
};

export interface InspectionMainContentProps {
  collapseContainerDetails?: boolean;
  displayContainerContent?: boolean;
  showEmptyState?: boolean;
}

export const InspectionMainContent = ({
  collapseContainerDetails,
  displayContainerContent,
  showEmptyState = false,
}: InspectionMainContentProps) => (
  <>
    <ScrollArea
      type='scroll'
      className='grow'
      classNames={{ viewport: 'scroll-p-3 scroll-smooth' }}
      mb='var(--voice-bar-height, 0px)'
      data-testid={testIds.inspectionMainContent}
    >
      <InspectionListContent
        collapseContainerDetails={collapseContainerDetails}
        displayContainerContent={displayContainerContent}
        showEmptyState={showEmptyState}
      />
    </ScrollArea>
    <VoiceBar />
  </>
);
