import { DatabaseTableKeys } from './db.types';

export const dbSchemaV1 = {
  flows: 'id, [id+activeVersion]',
  flowMetadata: 'id, [id+version]',
  executions: 'id, createdAt',
  reportedEvents: 'id, flowExecutionId',
  renderModels: '[flowId+version]',
  pendingActions: 'id',
  pendingBiEvents: '++',
} satisfies Partial<Record<DatabaseTableKeys, string>>;

export const dbSchemaV2 = {
  ...dbSchemaV1,
  flowMetadata: null,
  tempFlowMetadata: '[id+version]', // Temporary table for new schema
  dynamicContainers: 'id, flowExecutionId',
} satisfies Partial<Record<DatabaseTableKeys | 'tempFlowMetadata', string | null>>;

export const dbSchemaV3 = {
  ...dbSchemaV2,
  flowMetadata: '[id+version]',
  tempFlowMetadata: null,
} satisfies Partial<Record<DatabaseTableKeys | 'tempFlowMetadata', string | null>>;

export const dbSchemaV4 = {
  ...dbSchemaV3,
  renderModels: '[flowId+version]',
} satisfies Partial<Record<DatabaseTableKeys, string>>;
