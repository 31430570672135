import { Flex } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { GlobalFilters } from 'stores/filters';
import { FilterItem } from '../FilterItem/FilterItem';

interface GlobalFilterItemsProps {
  globalFilters: GlobalFilters;
  onChange: (key: keyof GlobalFilters, value: GlobalFilters[typeof key]) => void;
}

export const testIds = {
  wrapper: 'global-filter-items',
  getGlobalFilterTestId: (filter: keyof GlobalFilters) => `global-filter-${filter}`,
};

export const GlobalFilterItems = ({ globalFilters, onChange }: GlobalFilterItemsProps) => {
  const { t } = useTranslation();

  const handleChange = (filter: keyof GlobalFilters) => (value: boolean) => onChange(filter, value);
  const filters = Object.keys(globalFilters);

  return (
    <Flex w='100%' gap='xs' data-testid={testIds.wrapper}>
      {filters.map((key) => {
        const filter = key as keyof GlobalFilters;
        const value = globalFilters[filter];
        return (
          <FilterItem
            key={filter}
            checked={value}
            onChange={handleChange(filter)}
            data-testid={testIds.getGlobalFilterTestId(filter)}
          >
            {t(`inspection.filter.globalFilters.${filter}`)}
          </FilterItem>
        );
      })}
    </Flex>
  );
};
