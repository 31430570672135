import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { filterLeafContainers, orderContainers, useContainerStore } from 'stores/container';
import { isEventValid, useUiEventStore } from 'stores/uiEvent';
import { useReportStore } from 'stores/report';
import { useGetCEBridges, useGetMainEvent, useGetContainerStaticEvents } from 'stores/uiEvent/uiEvent.selectors';
import { isApplicableByReport, pullLastReport } from 'stores/report/report.utils';
import { ContainerItemSummaryProps } from 'pages';
import { Container } from '@flow/flow-backend-types';

interface ReviewStepsContainerIds {
  invalidContainerSummaries: ContainerItemSummaryProps[];
  validContainerSummaries: ContainerItemSummaryProps[];
  nonApplicableContainerSummaries: ContainerItemSummaryProps[];
}

export function useContainersByStep(): ReviewStepsContainerIds {
  const { validations } = useUiEventStore(['validations']);
  const { reports } = useReportStore(['reports']);
  const { containers, rootContainerIds } = useContainerStore(['containers', 'rootContainerIds']);
  const getContainerStaticEvents = useGetContainerStaticEvents();
  const getMainEvent = useGetMainEvent();
  const getCEBridges = useGetCEBridges();

  const placeSummary = (summaries: ReviewStepsContainerIds, summary: ContainerItemSummaryProps, isValid?: boolean) => {
    if (summary.notApplicable) summaries.nonApplicableContainerSummaries.push(summary);
    else if (isValid) summaries.validContainerSummaries.push(summary);
    else summaries.invalidContainerSummaries.push(summary);
  };

  const filterContainer = (summaries: ReviewStepsContainerIds, container: Container) => {
    const mainEvent = getMainEvent(container);
    if (!mainEvent) return;
    const { id: containerId } = container;
    const { applicabilityEventId } = getContainerStaticEvents(container.id);
    const applicabilityReport = pullLastReport(reports, containerId, applicabilityEventId ?? '');
    const ceBridges = getCEBridges(containerId);
    const mainEventCEBridge = ceBridges.find((bridge) => bridge.uiEventId === mainEvent.id);
    const mainEventValidationId = mainEventCEBridge?.validationIds[0];
    const mainEventValidation = mainEventValidationId ? validations[mainEventValidationId] : undefined;
    const notApplicable = !isApplicableByReport(applicabilityReport);
    const containerSummary: ContainerItemSummaryProps = {
      container,
      uiEvent: mainEvent,
      notApplicable,
    };
    const isValid = isEventValid(mainEvent, containerId, reports, mainEventValidation) !== false;
    placeSummary(summaries, containerSummary, isValid);
  };

  return useMemo(() => {
    const summaries: ReviewStepsContainerIds = {
      invalidContainerSummaries: [],
      validContainerSummaries: [],
      nonApplicableContainerSummaries: [],
    };
    const rootContainers = orderContainers(rootContainerIds.map((id) => containers[id]));
    rootContainers.forEach(({ id }) => {
      const leafContainers = filterLeafContainers(containers, id, []);
      leafContainers.forEach((container) => filterContainer(summaries, container));
    });

    return summaries;
  }, [containers, rootContainerIds, validations]);
}

export function useReviewSteps({
  invalidContainerSummaries,
  validContainerSummaries,
  nonApplicableContainerSummaries,
}: ReviewStepsContainerIds) {
  const { t } = useTranslation();
  const invalidTitle = t('review.invalidStepTitle');
  const validTitle = t('review.validStepTitle');
  const nonApplicableTitle = t('review.notApplicableStepTitle');
  return [
    {
      stepTitle: invalidTitle,
      containerSummaries: invalidContainerSummaries,
    },
    {
      stepTitle: validTitle,
      containerSummaries: validContainerSummaries,
    },
    {
      stepTitle: nonApplicableTitle,
      containerSummaries: nonApplicableContainerSummaries,
    },
  ];
}
