import { ChangeEventHandler, useEffect, useRef, useState } from 'react';
import { TimeInput } from '@mantine/dates';
import { ReportValueType, DateElementData } from '@flow/flow-backend-types';
import { validateNumber } from 'stores/uiEvent';
import { exists, formatTime, getValueFromFormattedTime } from 'utils';
import { ActionIcon, ComboboxClearButton, Group } from '@mantine/core';
import { IconClock } from '@tabler/icons-react';
import { NumericEventProps } from '../types';
import { useBoundError } from '../hooks';
import { EventValidityIcon, validityIconTestIds } from '../components';
import classes from './TimeEvent.module.css';

export const testIds = {
  getInputTestId: (id: string) => `time-event-input-${id}`,
  clearButton: 'time-event-clear-button',
  validity: validityIconTestIds,
};

function getTimeValue(value?: string | null) {
  return exists(value) ? formatTime(Number(value)) : '';
}

export const TimeEvent = ({
  title,
  disabled,
  elementData,
  reportKey,
  validation,
  valid,
  bounds,
  bounded,
  isEditing,
  lastEventReport,
  triggerFocus,
  triggerBlur,
  triggerReport,
}: NumericEventProps<DateElementData>) => {
  const targetRef = useRef<HTMLInputElement>(null);
  const lastReportedValue = lastEventReport?.reportedValue;
  const [value, setValue] = useState<string | undefined>(getTimeValue(lastReportedValue));
  const error = useBoundError(bounded, bounds, Number(lastReportedValue), 'TimeOfDayEvent', formatTime);

  const onClear = () => onChange(null);

  const onChange = (newValue?: number | null) => {
    const isValid = validateNumber(newValue ?? undefined, validation);
    triggerReport(
      {
        reportedValue: exists(newValue) ? String(newValue) : null,
        reportedValueType: ReportValueType.NUMBER,
      },
      isValid,
    );
  };

  const onChangeTime: ChangeEventHandler<HTMLInputElement> = (event) => {
    const newValue = event.currentTarget.value;
    setValue(newValue);
    const numberValue = getValueFromFormattedTime(newValue);
    if (!isEditing) onChange(numberValue);
  };

  const onBlur = () => {
    triggerBlur(targetRef.current?.value);
    const valueDidChange = getTimeValue(lastReportedValue) !== value;
    if (valueDidChange) onChange(getValueFromFormattedTime(value ?? ''));
  };

  useEffect(() => {
    if (!isEditing) setValue(getTimeValue(lastReportedValue));
  }, [lastReportedValue]);

  return (
    <TimeInput
      key={reportKey}
      ref={targetRef}
      disabled={disabled}
      label={title}
      classNames={classes}
      placeholder={elementData.placeHolder}
      rightSectionWidth={65}
      rightSection={
        <Group w='100%' wrap='nowrap' gap='tn' justify='flex-end' pr={5}>
          <EventValidityIcon valid={valid} />
          {exists(value) && <ComboboxClearButton onClear={onClear} data-testid={testIds.clearButton} />}
          <ActionIcon flex='auto 0' variant='subtle' color='gray' onClick={() => targetRef.current?.showPicker()}>
            <IconClock />
          </ActionIcon>
        </Group>
      }
      value={value}
      error={error}
      onBlur={onBlur}
      onChange={onChangeTime}
      onFocus={triggerFocus}
      data-testid={testIds.getInputTestId(reportKey)}
    />
  );
};
