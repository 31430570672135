import { Divider, Stack } from '@mantine/core';
import { useSetState } from '@mantine/hooks';
import { CEBridgeId, Container, ReportedValue } from '@flow/flow-backend-types';
import { useMemo } from 'react';
import { ImageEvent } from 'components';
import { useCEBridges, useContainerStaticEvents, useGetUiEvent, isUiEventStatic } from 'stores/uiEvent';
import { exists } from 'utils';
import { RenderEvent } from '../RenderEvent/RenderEvent';
import { ContainerItemFooter } from './ContainerItemFooter';
import { ChildEventsAccordion } from '../ChildEventsAccordion/ChildEventsAccordion';

interface ContainerDetailsProps {
  container: Container;
  notApplicable: boolean;
}

export const testIds = {
  getContainerById: (containerId: string) => `container-details-${containerId}`,
  getEventWrapperRowById: (uiEventId: string) => `event-row-${uiEventId}`,
};

export const ContainerDetails = ({ container, notApplicable }: ContainerDetailsProps) => {
  const [openedParents, setOpenedParents] = useSetState<Record<CEBridgeId, boolean>>({});
  const ceBridges = useCEBridges(container.id);
  const { imageEventId } = useContainerStaticEvents(container.id);
  const getUiEvent = useGetUiEvent();

  const readableBridges = useMemo(
    () => ceBridges.filter(({ uiEventId }) => !isUiEventStatic(getUiEvent(uiEventId))),
    [ceBridges],
  );

  const handleReportValueChange = (ceBridgeId: CEBridgeId) => (value?: ReportedValue) => {
    const childIds = ceBridges.find(({ id }) => ceBridgeId === id)?.childrenIds;
    if (exists(value) && exists(childIds) && childIds.length > 0) setOpenedParents({ [ceBridgeId]: true });
  };

  const toggleOpenedParentEventId = (ceBridgeId: CEBridgeId) => (open: boolean) =>
    setOpenedParents({ [ceBridgeId]: open });

  return (
    <Stack h='100%' justify='space-between' data-testid={testIds.getContainerById(container.id)}>
      <Stack gap={20}>
        {readableBridges.map((ceBridge) => {
          const uiEvent = getUiEvent(ceBridge.uiEventId);
          return (
            <Stack key={ceBridge.id} gap={4} data-testid={testIds.getEventWrapperRowById(ceBridge.uiEventId)}>
              <RenderEvent
                container={container}
                uiEvent={uiEvent}
                isMandatory={ceBridge.isMandatory}
                disabled={notApplicable}
                onReportValueChange={handleReportValueChange(ceBridge.id)}
              />
              {ceBridge.childrenIds.length > 0 && (
                <ChildEventsAccordion
                  open={openedParents[ceBridge.id]}
                  uiEvent={uiEvent}
                  container={container}
                  notApplicable={notApplicable}
                  toggleOpen={toggleOpenedParentEventId(ceBridge.id)}
                />
              )}
            </Stack>
          );
        })}
        {imageEventId && <ImageEvent disabled={notApplicable} />}
      </Stack>
      <Stack gap='tn' mt='md'>
        <Divider />
        <ContainerItemFooter container={container} />
      </Stack>
    </Stack>
  );
};
