import { logger, LoggerAlertCodes } from 'services/logger';
import { MEDIA_CONSTRAINTS, AUDIO_PARAMS, AUDIO_CONTEXT_TIMEOUT } from 'services/voice/recorder/recorder.const';
import { AudioFrame, WorkletMessageEvent } from 'services/voice/recorder/recorder.types';

const { SAMPLE_RATE } = AUDIO_PARAMS;

export class Recorder {
  private static instance: Recorder;
  private audioContext: AudioContext;
  private mediaStream?: MediaStream;
  private audioProcessorWorklet?: AudioWorkletNode;

  private constructor() {
    this.audioContext = new AudioContext({ sampleRate: SAMPLE_RATE });
    this.audioContext.suspend();
  }

  public static getInstance(): Recorder {
    if (!Recorder.instance) Recorder.instance = new Recorder();
    return Recorder.instance;
  }

  get status() {
    return this.audioContext.state === 'running' ? 'opened' : 'closed';
  }

  /**
   * Due to internal device implementation that is out of our control, `AudioContext`s can sometimes hang indefinitely.
   * These will release if another `suspend()` or `resume()` is called, which may cause double streams.
   * To handle these rogue contexts in development and production, we will restart the context if it hangs.
   * This is not required in testing as it disrupts test setup, which already recreates the context for every test.
   */
  private async restartAudioContext() {
    let timeout: NodeJS.Timeout;
    await Promise.race([
      new Promise<void>((resolve, reject) => {
        timeout = setTimeout(() => {
          if (import.meta.env.MODE !== 'test') {
            if (this.audioContext.state === 'running') resolve();
            else {
              this.audioContext.close();
              this.audioContext = new AudioContext({ sampleRate: SAMPLE_RATE });
              this.audioContext.suspend();
              console.info('Audio context recreated due to error! 🔄');
              logger.error('Audio context timeout when resuming.', {
                alert: LoggerAlertCodes.AUDIO_CTX_TIMEOUT,
              });
              reject(new Error('Resuming existing context timed out! ⏰'));
            }
          }
        }, AUDIO_CONTEXT_TIMEOUT);
      }),
      this.audioContext.resume().then(() => {
        console.info('Audio context resumed! ▶️');
        clearTimeout(timeout);
      }),
    ]);
  }

  async start(onAudioFrame: (audioFrame: AudioFrame) => void) {
    try {
      if (this.audioContext.state === 'suspended' && !this.audioProcessorWorklet) {
        await this.restartAudioContext();

        this.mediaStream = await navigator.mediaDevices.getUserMedia(MEDIA_CONSTRAINTS);
        const micNode = this.audioContext.createMediaStreamSource(this.mediaStream);
        await this.audioContext.audioWorklet.addModule('/worklet/audio-processor.js');

        this.audioProcessorWorklet = new AudioWorkletNode(this.audioContext, 'audio-processor');
        this.audioProcessorWorklet.port.onmessage = (event: WorkletMessageEvent) => onAudioFrame(event.data);
        micNode.connect(this.audioProcessorWorklet).connect(this.audioContext.destination);
        console.info('Audio recorder started! 🎤');
      } else {
        console.info('Tried to start an already started recorder! ✋');
      }
    } catch (e) {
      throw new Error('Error starting the audio recorder! 🛑', { cause: e });
    }
  }

  async stop() {
    try {
      if (this.audioContext.state !== 'suspended' && this.audioProcessorWorklet) {
        this.mediaStream?.getTracks().forEach((track) => track.stop());

        this.audioProcessorWorklet.port.postMessage('stop');
        this.audioProcessorWorklet.disconnect();
        this.audioProcessorWorklet = undefined;

        // Suspend the AudioContext to effectively pause the recording process
        this.audioContext.suspend().then(() => console.info('Audio context suspended! ⏸️'));
        console.info('Audio recorder stopped! 🛑');
      } else {
        console.info('Tried to stop a stopped recorder! ✋');
      }
    } catch {
      throw new Error('Error stopping the audio recorder! 🛑');
    }
  }
}
