import cn from 'classnames';
import { Avatar, Card, Flex, Text, Title, useMantineTheme } from '@mantine/core';
import { UserIdentity } from '@flow/flow-backend-types';
import { useMemo } from 'react';
import { names, useSpy, SelectExecution } from 'services/espionage';
import { useCurrentUser } from 'stores/auth';
import { useOnline } from 'stores/network';
import { finishedExecutionStatuses, getInitials } from 'utils';
import { useMetadataExposedFields, Execution } from 'stores/flow';
import { StatusLabel } from './StatusLabel/StatusLabel';
import classes from './ExecutionItem.module.css';

interface ExecutionItemProps {
  execution: Execution;
  title?: string;
  onClick?: () => void;
}

export const MAX_DISPLAYED_USERS = 3;
export const testIds = {
  getItemCardId: (cardId: string) => `executionCard-${cardId}`,
  itemTitleName: 'execution-title-name',
  itemTitleStatus: 'execution-title-status',
  avatarGroup: 'execution-avatar-group',
  userAvatar: 'execution-user-avatar',
  restUserCount: 'execution-rest-user-count',
};

export const ExecutionItem = ({ execution, title, onClick }: ExecutionItemProps) => {
  const { colors } = useMantineTheme();
  const online = useOnline();
  const currentUser = useCurrentUser();
  const { spyClick } = useSpy();
  const exposed = useMetadataExposedFields(execution.id);

  const isInProgress = execution.status === 'inProgress';
  const isInReview = execution.status === 'inReview';
  const isPending = execution.status === 'pending';
  const isCompleted = finishedExecutionStatuses.includes(execution.status);

  const users = execution.joinedUsers;
  const currentUserId = currentUser?.userId ?? '';

  const { isCurrentUserJoined, usersToDisplay, restUserCount, isExecutionJoinable } = useMemo(() => {
    const isJoined = users.some(({ userId }: UserIdentity) => userId === currentUserId);
    const others = users.filter(({ userId }: UserIdentity) => userId !== currentUserId);
    const onlyCurrentUserJoined = users.length === 1 && isJoined;
    const isJoinable = online || onlyCurrentUserJoined;
    const allUsers = isJoined ? [currentUser, ...others] : others;
    const displayed = allUsers.slice(0, MAX_DISPLAYED_USERS);
    const rest = Math.max(users.length - MAX_DISPLAYED_USERS, 0);
    return {
      isCurrentUserJoined: isJoined,
      usersToDisplay: displayed,
      restUserCount: rest,
      isExecutionJoinable: isJoinable,
    };
  }, [execution, currentUser, online]);

  const sendBiEvent = () => {
    const { NO_ACTION, JOIN, CONTINUE } = SelectExecution;
    let spyActionType: string = NO_ACTION;
    if (isExecutionJoinable && !isCompleted) {
      spyActionType = isCurrentUserJoined ? CONTINUE : JOIN;
    }
    spyClick(names.FlowListPage.SelectExecution, {
      executionId: execution.id,
      status: execution.status,
      actionType: spyActionType,
    });
  };

  const onClickByCard = () => {
    sendBiEvent();
    if (isCompleted || !isExecutionJoinable) return;
    onClick?.();
  };

  const getAvatarColor = (participantId?: string) => {
    const isCurrentUser = participantId === currentUser?.userId;
    if (!isExecutionJoinable && isCurrentUser) return colors.gray[6];
    if (!isExecutionJoinable && !isCurrentUser) return colors.gray[5];
    if (isCurrentUser) return colors.teal[8];
    return colors.teal[3];
  };

  return (
    <Card
      p='md'
      radius={6}
      className={cn(classes.cardBorder, {
        [classes.successBorder]: isCurrentUserJoined && (isInProgress || isInReview),
        [classes.disabledBorder]: isCurrentUserJoined && isInProgress && !isExecutionJoinable,
        [classes.pendingBorder]: isCurrentUserJoined && isPending,
      })}
      onClick={onClickByCard}
      data-testid={testIds.getItemCardId(execution.id)}
    >
      <Title
        order={5}
        fw={400}
        mb='sm'
        c={isCompleted || !isExecutionJoinable ? colors.gray[6] : colors.gray[7]}
        data-testid={testIds.itemTitleName}
      >
        {title}
      </Title>
      <Flex gap='sm' align='center' w='100%'>
        {!!users.length && (
          <Avatar.Group data-testid={testIds.avatarGroup}>
            {usersToDisplay.map((participant) => (
              <Avatar
                key={participant?.userId}
                size={28}
                variant='filled'
                color={getAvatarColor(participant?.userId)}
                data-testid={testIds.userAvatar}
              >
                {getInitials(`${participant?.givenName} ${participant?.familyName}`)}
              </Avatar>
            ))}
            {restUserCount > 0 && (
              <Avatar
                color={!isExecutionJoinable ? colors.gray[5] : colors.teal[3]}
                size={28}
                data-testid={testIds.restUserCount}
              >
                {`+${restUserCount}`}
              </Avatar>
            )}
          </Avatar.Group>
        )}
        <Text
          size='tiny'
          c={!isExecutionJoinable || isCompleted ? colors.gray[6] : colors.teal[7]}
          fw={400}
          lineClamp={2}
          className='grow break-words'
          data-testid={testIds.itemTitleStatus}
        >
          {exposed.at(0)}
        </Text>
        <StatusLabel status={execution.status} isUserJoined={isCurrentUserJoined} isJoinable={isExecutionJoinable} />
      </Flex>
    </Card>
  );
};
