import { ThemeIcon } from '@mantine/core';
import { IconCircleCheckFilled, IconCircleXFilled } from '@tabler/icons-react';
import { ReactElement } from 'react';

interface EventValidityIconProps {
  valid?: boolean;
}

export const testIds = {
  icon: 'event-base-input-icon',
  valid: 'event-base-input-valid',
  invalid: 'event-base-input-invalid',
};

const validityIcon: Record<string, ReactElement> = {
  true: <IconCircleCheckFilled data-testid={testIds.valid} />,
  false: <IconCircleXFilled data-testid={testIds.invalid} />,
};

export const EventValidityIcon = ({ valid }: EventValidityIconProps) => (
  <ThemeIcon color={valid ? 'green' : 'red'} variant='transparent' size='sm' data-testid={testIds.icon}>
    {validityIcon[String(valid)]}
  </ThemeIcon>
);
