import { Avatar, UnstyledButton } from '@mantine/core';
import { useAuthStore } from 'stores/auth';
import { getInitials } from 'utils';
import { UserMenu } from 'layouts';

export const testIds = {
  userAvatar: 'user-avatar',
};

export const UserAvatar = () => {
  const { currentUser } = useAuthStore(['currentUser']);
  const fullName = currentUser ? `${currentUser.givenName} ${currentUser.familyName}` : '';

  return (
    currentUser && (
      <UserMenu showUser>
        <UnstyledButton w={44} h={44} className='group flex items-center justify-end'>
          <Avatar
            data-testid={testIds.userAvatar}
            variant='filled'
            color='green.9'
            classNames={{
              placeholder: 'group-[&[data-expanded]]:!bg-black group-[&[data-expanded]]:!text-white transition-colors',
            }}
          >
            {getInitials(fullName)}
          </Avatar>
        </UnstyledButton>
      </UserMenu>
    )
  );
};
