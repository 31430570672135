import { Divider, Flex, Stack, Title, UnstyledButton } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconChevronDown } from '@tabler/icons-react';
import cn from 'classnames';
import { UiEventFilter, ValidationFilter } from 'stores/filters';
import { HiddenContentCollapse } from 'components';
import { GroupEventItems } from './GroupEventItems/GroupEventItems';
import { EventValidationFilter } from './EventValidationFilter/EventValidationFilter';

export const testIds = {
  getEventFilterButton: (eventId: string) => `event-filter-button-${eventId}`,
  eventFilterIconChevron: 'event-filter-icon-chevron',
  eventFilterTitle: 'event-filter-title',
  eventValuesFilterTitle: 'event-values-filter-title',
  eventFilterCounter: (id: string) => `event-filter-counter-${id}`,
};

interface EventFilterItemProps {
  filter: UiEventFilter;
  filterValues: string[];
  validationFilterValues: ValidationFilter[];
  onFilterChanged: (values: string[]) => void;
  onValidationFilterChanged: (values: ValidationFilter[]) => void;
}

export const EventFilterItem = ({
  filterValues,
  validationFilterValues,
  filter,
  onFilterChanged,
  onValidationFilterChanged,
}: EventFilterItemProps) => {
  const [opened, { toggle }] = useDisclosure(false);
  const { uiEventId, title, values, hasValidations } = filter;
  const filterValuesCount = filterValues.length + validationFilterValues.length;

  return (
    <Flex direction='column' key={uiEventId}>
      <UnstyledButton
        w='100%'
        py='sm'
        mih={56}
        className='flex items-center gap-2 justify-between'
        onClick={toggle}
        data-testid={testIds.getEventFilterButton(uiEventId)}
      >
        <Flex w='100%' justify='space-between' gap={8}>
          <Title fw={500} size={16} w='90%' data-testid={testIds.eventFilterTitle}>
            <Flex align='center'>
              {title}
              {filterValuesCount > 0 && `(${filterValuesCount})`}
            </Flex>
          </Title>
          <IconChevronDown
            className={cn(opened ? 'rotate-180' : 'rotate-0', 'transition-transform')}
            width='10%'
            size={20}
            data-testid={testIds.eventFilterIconChevron}
          />
        </Flex>
      </UnstyledButton>
      <Flex direction='column' gap='sm'>
        <HiddenContentCollapse open={opened}>
          <Stack gap='lg'>
            <EventValidationFilter
              uiEventId={uiEventId}
              eventHasValidations={hasValidations}
              validationFilterValues={validationFilterValues}
              onFilterChanged={onValidationFilterChanged}
            />
            <GroupEventItems
              uiEventId={uiEventId}
              eventValues={values}
              filterValues={filterValues}
              onFilterChanged={onFilterChanged}
            />
          </Stack>
        </HiddenContentCollapse>
        <Divider color='gray.2' />
      </Flex>
    </Flex>
  );
};
