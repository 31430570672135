import { AudioMetadata, AudioSocket, ReportingContext, VoicePacket } from 'services/voice/audioSocket';
import { AudioFrame, Recorder } from 'services/voice/recorder';
import { StartAudioRecordingParams } from 'services/voice/voiceManager/voiceManager.types';

export class VoiceManager {
  private static instance: VoiceManager;
  private audioSocket: AudioSocket;
  private recorder: Recorder;
  private _context?: ReportingContext;

  private constructor() {
    this.recorder = Recorder.getInstance();
    this.audioSocket = AudioSocket.getInstance();
  }

  public static getInstance(): VoiceManager {
    if (!VoiceManager.instance) VoiceManager.instance = new VoiceManager();
    return VoiceManager.instance;
  }

  public async startAudioRecording({ onPacket, ...params }: StartAudioRecordingParams) {
    try {
      await this.audioSocket.open(params);
      await this.recorder.start(({ rms, ...frame }: AudioFrame) => {
        onPacket(rms);
        const packet: VoicePacket = {
          id: frame.bufferId,
          audioFrame: frame.buffer,
          timestamp: frame.timestamp,
        };
        if (this._context) {
          this.audioSocket.sendMessage('binary_data_with_context', {
            ...packet,
            ...this._context,
          });
        } else {
          this.audioSocket.sendMessage('binary_data', {
            id: frame.bufferId,
            audioFrame: frame.buffer,
            timestamp: frame.timestamp,
          });
        }
      });
    } catch (error) {
      throw new Error('startAudioError', { cause: error });
    }
  }

  public async stopAudioRecording() {
    try {
      await this.recorder.stop();
      await this.audioSocket.close();
    } catch (error) {
      throw new Error('stopAudioError', { cause: error });
    }
  }

  set metadata(metadata: Partial<AudioMetadata>) {
    this.audioSocket.sendMessage('metadata_update', metadata);
  }

  set context(context: ReportingContext | undefined) {
    this._context = context;
  }
}
