interface ILoggerProvider {
  setUser(user: UserLoggerData): void;
  log(severity: Severity, message: LogMessage): void;
}

export enum Severity {
  INFO = 'INFO',
  WARN = 'WARN',
  ERROR = 'ERROR',
}

export type LogTag = 'offline' | 'init';
export interface LogMessage {
  message: string;
  meta: MetaData;
}

export interface LogPayload extends Record<string, any> {
  tags?: LogTag[];
}

// TODO: add more fields as needed
export type MetaData = {
  tenant?: {
    id: string;
  };
};

export type UserLoggerData = {
  userId: string;
  userName: string;
  userEmailAddress?: string;
};

export type LoggerProvider = ILoggerProvider;

export default ILoggerProvider;

export enum LoggerAlertCodes {
  AUDIO_CTX_TIMEOUT,
}
