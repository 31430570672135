import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { DevtoolsOptions, devtools } from 'zustand/middleware';
import { Flow, FlowRef, StartExecutionRequest, User, UserIdentity } from '@flow/flow-backend-types';
import { arrayToMap } from 'utils';
import { authStore } from 'stores/auth';
import { createStoreHook, userToIdentity } from '@aiola/frontend';
import { db } from 'services/db';
import { useShallow } from 'zustand/react/shallow';
import { settingsStore } from 'stores/settings';
import { config } from 'services/config';
import { Execution, ExecutionError, ExecutionsMap, FlowsMap } from './flow.types';
import { flowsApi } from './flow.api';
import {
  createExecution,
  generateUniqueIdentifier,
  getTimeAgo,
  joinUserToExecution,
  markExecutionAsCanceled,
  markExecutionAsFinished,
  markExecutionAsInProgress,
  markExecutionAsReviewed,
} from './flow.utils';

interface FlowState {
  flows: FlowsMap;
  executions: ExecutionsMap;
  currentExecutionId: string | undefined;
  currentExecution: Execution | undefined;
  isLoadingFlows: boolean;
  isLoadingExecutions: boolean;
  executionError?: ExecutionError;
}

interface FlowActions {
  fetchFlows: () => Promise<Flow[] | undefined>;
  fetchExecutions: () => Promise<Execution[] | undefined>;
  loadFlows: () => Promise<void>;
  loadExecutions: () => Promise<void>;
  startExecution: (executionData: StartExecutionRequest) => Promise<Execution | undefined>;
  reviewExecution: (flowExecutionId: string) => Promise<Execution>;
  continueExecution: (flowExecutionId: string) => Promise<Execution>;
  joinExecution: (flowExecutionId: string) => Promise<boolean>;
  cancelExecution: (flowExecutionId: string) => Promise<boolean>;
  finishExecution: (flowExecutionId: string) => Promise<boolean>;
  handleExecutionNotification: (execution: Execution, isCurrentSession: boolean) => void;
  getFlowById: (flowId: string) => Flow;
  setExecution: (execution: Execution) => Promise<void>;
  setCurrentExecutionId: (executionId: string | undefined) => void;
  setCurrentExecution: (executionId: Execution | undefined) => void;
  getExecutionUniqueIdentifier: (
    FlowRef: FlowRef,
    userIdentity: UserIdentity,
    uniqueIdentifierValue: string,
    uniqueIdentifierFieldId?: string,
  ) => string;
  setExecutionError: (executionError?: ExecutionError) => void;
  deleteExecution: (flowExecutionId: string) => Promise<void>;
  updatePendingExecutionUniqueId: (flowExecutionId: string, newUniqueIdentifier: string) => Promise<void>;
  reset: () => void;
}

const initialState: FlowState = {
  flows: {},
  executions: {},
  currentExecutionId: undefined,
  currentExecution: undefined,
  isLoadingFlows: true,
  isLoadingExecutions: true,
  executionError: undefined,
};

const devtoolsOptions: DevtoolsOptions = {
  name: 'flows',
  store: 'flows',
  enabled: process.env.NODE_ENV === 'development',
};

export const flowStore = create(
  devtools(
    immer<FlowState & FlowActions>((set, get) => ({
      ...initialState,
      fetchFlows: async () => flowsApi.fetchFlows(),
      fetchExecutions: async () => {
        const { fetchExecutionsByStatus } = flowsApi;
        const timeLimit = getTimeAgo(config.onGoingExecutionTimeLimit);
        try {
          const allExecutionsArrays = await Promise.all([
            fetchExecutionsByStatus('inProgress'),
            fetchExecutionsByStatus('inReview'),
            fetchExecutionsByStatus('done', timeLimit),
            fetchExecutionsByStatus('expired', timeLimit),
            fetchExecutionsByStatus('cancelled', timeLimit),
          ]);
          return allExecutionsArrays.flat();
        } catch (error) {
          return undefined;
        }
      },
      loadFlows: async () => {
        set({ isLoadingFlows: true });
        const flows = await db.flows.toArray();
        set((state) => {
          state.flows = arrayToMap<Flow>(flows, 'id');
          state.isLoadingFlows = false;
        });
      },
      loadExecutions: async () => {
        set({ isLoadingExecutions: true });
        const executions = await db.executions.toArray();
        set((state) => {
          state.executions = arrayToMap<Execution>(executions, 'id');
          state.isLoadingExecutions = false;
        });
      },
      startExecution: async ({ flowId, version, preInspectionMetadata, uniqueIdentifier }) => {
        const { setExecution, getExecutionUniqueIdentifier } = get();
        const currentUser = authStore.getState().currentUser as User;
        const flowRef: FlowRef = { id: flowId, version };
        const userIdentity = userToIdentity(currentUser);

        const executionUniqueIdentifier = getExecutionUniqueIdentifier(flowRef, userIdentity, uniqueIdentifier);

        if (!executionUniqueIdentifier) return undefined;

        const execution = createExecution(
          {
            flowRef,
            preInspectionMetadata,
            uniqueIdentifier: executionUniqueIdentifier,
          },
          userIdentity,
        );

        await setExecution(execution);
        db.storePendingAction({
          type: 'startExecution',
          payload: {
            id: execution.id,
            flowId: execution.flowRef.id,
            version: execution.flowRef.version,
            preInspectionMetadata: execution.preInspectionMetadata,
            uniqueIdentifier: execution.uniqueIdentifier,
            originalTZName: execution.originalTZName,
          },
        });

        return execution;
      },
      getExecutionUniqueIdentifier: (_, userIdentity, uniqueIdentifierValue) => {
        const { settings } = settingsStore.getState();
        return uniqueIdentifierValue ?? generateUniqueIdentifier(userIdentity, settings.dateFormat);
      },
      joinExecution: async (flowExecutionId) => {
        const { executions, flows, setExecution } = get();
        const execution = executions[flowExecutionId];
        const { maxInspectors } = flows[execution.flowRef.id];
        const currentUser = authStore.getState().currentUser as User;
        const userLimitExceeded = execution.joinedUsers.length >= maxInspectors;

        if (execution.joinedUsers.some(({ userId }) => userId === currentUser.userId)) return true;
        if (maxInspectors && userLimitExceeded) return false;

        const updatedExecution = joinUserToExecution(execution, userToIdentity(currentUser));
        await setExecution(updatedExecution);
        db.storePendingAction({ type: 'joinExecution', payload: { flowExecutionId } });

        return true;
      },
      reviewExecution: async (flowExecutionId) => {
        const { executions, setExecution } = get();
        const execution = executions[flowExecutionId];
        const currentUser = authStore.getState().currentUser as User;

        const updatedExecution = markExecutionAsReviewed(execution, userToIdentity(currentUser));
        await setExecution(updatedExecution);
        db.storePendingAction({ type: 'reviewExecution', payload: { flowExecutionId } });

        return updatedExecution;
      },
      continueExecution: async (flowExecutionId) => {
        const { executions, setExecution } = get();
        const execution = executions[flowExecutionId];

        const updatedExecution = markExecutionAsInProgress(execution);
        await setExecution(updatedExecution);
        db.storePendingAction({ type: 'continueExecution', payload: { flowExecutionId } });

        return updatedExecution;
      },
      finishExecution: async (flowExecutionId) => {
        const { executions, setExecution } = get();
        const execution = executions[flowExecutionId];
        const currentUser = authStore.getState().currentUser as User;

        const updatedExecution = markExecutionAsFinished(execution, userToIdentity(currentUser));
        await setExecution(updatedExecution);
        db.storePendingAction({ type: 'finishExecution', payload: { flowExecutionId } });

        return true;
      },
      cancelExecution: async (flowExecutionId) => {
        const { executions, setExecution } = get();
        const execution = executions[flowExecutionId];
        const currentUser = authStore.getState().currentUser as User;

        const updatedExecution = markExecutionAsCanceled(execution, userToIdentity(currentUser));
        await setExecution(updatedExecution);
        db.storePendingAction({ type: 'cancelExecution', payload: { flowExecutionId } });

        return true;
      },
      handleExecutionNotification: (execution, isCurrentSession) => {
        const { setExecution } = get();
        const shouldIgnore = isCurrentSession && execution.status !== 'done';
        if (!shouldIgnore) setExecution(execution);
      },
      getFlowById: (flowId) => get().flows[flowId],
      setExecution: async (execution) => {
        await db.executions.put(execution);
        set((state) => {
          state.executions[execution.id] = execution;
        });
      },
      setCurrentExecutionId: (executionId) => set({ currentExecutionId: executionId }),
      setCurrentExecution: (execution) => set({ currentExecution: execution }),
      setExecutionError: (executionError) => set({ executionError }),
      updatePendingExecutionUniqueId: async (flowExecutionId, newUniqueIdentifier) => {
        const { setExecution } = get();

        const { execution } = await db.getExecutionData(flowExecutionId);

        const updatedExecution: Execution = {
          ...(execution as Execution),
          uniqueIdentifier: newUniqueIdentifier,
        };

        await setExecution(updatedExecution);

        await db.storePendingAction({
          type: 'startExecution',
          payload: {
            id: updatedExecution.id,
            flowId: updatedExecution.flowRef.id,
            version: updatedExecution.flowRef.version,
            preInspectionMetadata: updatedExecution.preInspectionMetadata,
            uniqueIdentifier: updatedExecution.uniqueIdentifier,
            originalTZName: updatedExecution.originalTZName,
          },
        });
      },
      deleteExecution: async (flowExecutionId) => {
        await db.deleteExecutionData([flowExecutionId]);
        set((state) => {
          delete state.executions[flowExecutionId];
        });
      },
      reset: () => set(initialState),
    })),
    devtoolsOptions,
  ),
);

export const useFlowStore = createStoreHook<FlowState & FlowActions>({ store: flowStore, useShallow });
