import { useEffect, useRef, useState } from 'react';
import { DatePickerInput } from '@mantine/dates';
import { ReportValueType, DateElementData } from '@flow/flow-backend-types';
import { ComboboxClearButton, Group } from '@mantine/core';
import { useUserDateFormat } from 'stores/settings';
import { validateNumber } from 'stores/uiEvent';
import { exists } from 'utils';
import dayjs from 'dayjs';
import { EventValidityIcon, validityIconTestIds } from '../components';
import { NumericEventProps } from '../types';
import { useBoundError } from '../hooks';

export const testIds = {
  getButtonTestId: (id: string) => `date-event-input-${id}`,
  clearButton: 'date-event-clear-button',
  validity: validityIconTestIds,
};

function getObjectValue(value?: string | null) {
  return exists(value) ? new Date(Number(value)) : null;
}

export const DateEvent = ({
  title,
  disabled,
  reportKey,
  validation,
  valid,
  bounds,
  bounded,
  isEditing,
  lastEventReport,
  triggerFocus,
  triggerBlur,
  triggerReport,
}: NumericEventProps<DateElementData>) => {
  const targetRef = useRef<HTMLButtonElement>(null);
  const dateFormat = useUserDateFormat();
  const lastReportedValue = lastEventReport?.reportedValue;
  const [value, setValue] = useState<Date | null>(getObjectValue(lastReportedValue));
  const error = useBoundError(bounded, bounds, value?.getTime(), 'DateEvent', (v) => dayjs(v).format(dateFormat));

  const onChangeDate = (newDate: Date | null) => {
    const newValue = newDate?.getTime();
    const isValid = validateNumber(newValue, validation);
    triggerReport(
      {
        reportedValue: exists(newValue) ? String(newValue) : null,
        reportedValueType: ReportValueType.NUMBER,
      },
      isValid,
    );
  };

  const onClear = () => onChangeDate(null);

  const onBlur = () => triggerBlur(targetRef.current?.value);

  useEffect(() => {
    if (!isEditing) setValue(getObjectValue(lastReportedValue));
  }, [lastReportedValue]);

  return (
    <DatePickerInput
      key={reportKey}
      ref={targetRef}
      allowDeselect
      disabled={disabled}
      label={title}
      placeholder={dateFormat}
      popoverProps={{ returnFocus: false, trapFocus: false }}
      rightSectionWidth={60}
      rightSection={
        <Group wrap='nowrap' gap='tn'>
          <EventValidityIcon valid={valid} />
          {exists(value) && <ComboboxClearButton onClear={onClear} data-testid={testIds.clearButton} />}
        </Group>
      }
      value={value}
      valueFormat={dateFormat}
      error={error}
      onBlur={onBlur}
      onChange={onChangeDate}
      onFocus={triggerFocus}
      data-testid={testIds.getButtonTestId(reportKey)}
    />
  );
};
