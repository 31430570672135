import { useEffect } from 'react';
import { config } from 'services/config';
import { useAppStore } from 'stores/app';
import { useFlowStore } from 'stores/flow';
import { useOnline } from 'stores/network';

export const useCacheSync = () => {
  const online = useOnline();
  const { executeCacheSync } = useAppStore(['executeCacheSync']);
  const { loadFlows, loadExecutions } = useFlowStore(['loadFlows', 'loadExecutions']);

  useEffect(() => {
    let syncInterval: NodeJS.Timeout;

    if (online) {
      // Sync immediately when coming back online/on first app load
      executeCacheSync();
      // Then set up periodic sync
      syncInterval = setInterval(executeCacheSync, config.periodicSyncInterval);
    } else {
      // Load data from cache when offline
      loadFlows();
      loadExecutions();
    }

    return () => {
      if (syncInterval) {
        clearInterval(syncInterval);
      }
    };
  }, [online]);
};
