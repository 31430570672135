import { create, StoreApi, UseBoundStore } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { devtools, DevtoolsOptions } from 'zustand/middleware';
import { useShallow } from 'zustand/react/shallow';
import { flowStore } from 'stores/flow';
import { createStoreHook, noop } from '@aiola/frontend';
import { authStore } from 'stores/auth';
import { settingsStore } from 'stores/settings';
import { VoiceManager } from 'services/voice/voiceManager/voiceManager.class';
import { AudioMetadata, ReportingContext, SocketUrl, VoiceMode } from 'services/voice/audioSocket';
import { networkStore } from 'stores/network';
import { nanoid } from 'nanoid';
import { MicrophonePermission, VoiceErrorReason, VoiceStatus, VoiceTransition } from './voice.types';
import { getMicrophonePermission, getWebSocketUrl } from './voice.utils';
import { migrateVoiceSettings } from './voice.migrations';

const devtoolsOptions: DevtoolsOptions = {
  name: 'voice',
  store: 'voice',
  enabled: process.env.NODE_ENV === 'development',
};

migrateVoiceSettings();

interface VoiceState {
  voiceManager: VoiceManager;
  transcript: string;
  duringVoiceTransition: boolean;
  currentVoiceStatus: VoiceStatus;
  voiceError?: VoiceErrorReason;
  currentRms?: number;
  isMicPermitted?: boolean;
  reportingContext?: ReportingContext;
}

interface VoiceActions {
  changeVoiceState: (transition: VoiceTransition, context?: ReportingContext) => Promise<void>;
  setVoiceError: (error: VoiceErrorReason | undefined) => void;
  updateStreamMetadata: (metadata: Partial<AudioMetadata>) => Promise<void>;
  internal: {
    moveToStatus: (newStatus: VoiceStatus, context?: ReportingContext) => Promise<void>;
    startListening: () => Promise<void>;
    stopListening: () => Promise<void>;
    /** Silently stop voice services and soft-reset the store */
    forceStop: () => Promise<void>;
    requestMicPermission: () => Promise<MicrophonePermission>;
    getSocketUrl: (currentExecutionId: string) => SocketUrl;
    /** Generate a new stream ID and update the existing socket. */
    initStream: () => void;
    /** Sets or removes the current recording context, and initiates a new stream. */
    setContext: (context?: ReportingContext) => void;
  };
  reset: () => void;
}

const initialState: VoiceState = {
  voiceManager: VoiceManager.getInstance(),
  transcript: '',
  duringVoiceTransition: false,
  currentRms: undefined,
  currentVoiceStatus: 'closed',
  voiceError: undefined,
  isMicPermitted: undefined,
  reportingContext: undefined,
};

export const voiceStore = create(
  devtools(
    immer<VoiceState & VoiceActions>((set, get) => ({
      ...initialState,
      changeVoiceState: async (transition, context) => {
        const { currentVoiceStatus, duringVoiceTransition, internal, reportingContext } = get();
        const { moveToStatus } = internal;
        // Exit condition if we're currently during a transition
        if (duringVoiceTransition) return;

        set({ duringVoiceTransition: true });

        switch (transition) {
          case 'open':
            if (currentVoiceStatus === 'closed') {
              await moveToStatus('listening', context);
            }
            break;
          case 'close':
            if (currentVoiceStatus === 'listening') {
              await moveToStatus('closed');
            }
            break;
          case 'pause':
            if (currentVoiceStatus === 'listening') {
              await moveToStatus('paused');
            }
            break;
          case 'resume':
            if (currentVoiceStatus === 'paused') {
              await moveToStatus('listening', context ?? reportingContext);
            }
            break;
          default:
            break;
        }
        set({ duringVoiceTransition: false });
      },
      setVoiceError: (error) => {
        if (error) get().internal.forceStop();
        set({ voiceError: error });
      },
      updateStreamMetadata: async (metadata) => {
        const { voiceManager } = get();
        voiceManager.metadata = metadata;
      },

      internal: {
        moveToStatus: async (newStatus, context) => {
          const { internal, setVoiceError } = get();
          const { startListening, stopListening, setContext } = internal;
          try {
            switch (newStatus) {
              case 'listening':
                await startListening();
                setContext(context);
                break;
              case 'paused':
                await stopListening();
                break;
              case 'closed':
                await stopListening();
                setContext();
                break;
              default:
                break;
            }
            set({ currentVoiceStatus: newStatus, currentRms: undefined, transcript: '', voiceError: undefined });
          } catch (e) {
            const error = e as Error;
            console.error(error);
            setVoiceError(error.message as VoiceErrorReason);
          }
        },
        startListening: async () => {
          const { internal, voiceManager, setVoiceError } = get();
          const { currentExecutionId } = flowStore.getState();

          if (!currentExecutionId) throw new Error('noExecution');

          const { permitted, error } = await internal.requestMicPermission();
          const socketUrl = internal.getSocketUrl(currentExecutionId);
          if (error) throw new Error(error);
          set({ isMicPermitted: permitted });

          const onError = async () => {
            const { online } = networkStore.getState();
            setVoiceError(online ? 'socketError' : 'offlineError');
          };

          await voiceManager.startAudioRecording({
            socketUrl,
            onPacket: (rms) => {
              set({ currentRms: rms });
            },
            onTranscript: (newTranscript) => {
              set(({ transcript }) => ({ transcript: `${transcript} ${newTranscript}` }));
            },
            onError,
            onConnect: noop,
            onDisconnect: onError,
          });
        },
        stopListening: async () => {
          const { voiceManager } = get();
          await voiceManager.stopAudioRecording();
        },
        requestMicPermission: async () => {
          const { isMicPermitted } = get();
          if (isMicPermitted) return { permitted: true };
          return getMicrophonePermission();
        },
        forceStop: async () => {
          const { voiceManager } = get();
          await voiceManager.stopAudioRecording().catch(console.error);
          set({ currentRms: undefined, currentVoiceStatus: 'closed', transcript: '', reportingContext: undefined });
        },
        getSocketUrl: (currentExecutionId) => {
          const { flows, executions } = flowStore.getState();
          const flow = flows[executions[currentExecutionId].flowRef.id];
          const languageCode = settingsStore.getState().getCurrentLanguage();
          const { currentUser, sessionId } = authStore.getState();

          return getWebSocketUrl({
            flowSessionId: sessionId!,
            flowId: flow.id,
            version: flow.activeVersion,
            languageCode,
            voiceMode: VoiceMode.FREE_SPEECH,
            userId: currentUser?.userId ?? '',
            executionId: currentExecutionId,
            frameFormat: 'debug',
          });
        },
        initStream: async () => {
          get().updateStreamMetadata({ streamId: nanoid() });
        },
        setContext: (context) => {
          get().internal.initStream();
          get().voiceManager.context = context;
          set({ reportingContext: context });
        },
      },
      reset: () => {
        get().internal.forceStop();
        set({ ...initialState, voiceManager: VoiceManager.getInstance() });
      },
    })),
    devtoolsOptions,
  ),
);

export const useVoiceStore = createStoreHook<VoiceState & VoiceActions>({
  store: voiceStore as UseBoundStore<StoreApi<VoiceState & VoiceActions>>,
  useShallow,
});
