import { Flex } from '@mantine/core';
import { useEffect, useState } from 'react';
import { Outlet, useBlocker } from 'react-router-dom';
import { names, useSpy } from 'services/espionage';
import { appStore, useAppStore, DeviceOrientation, useDistraction } from 'stores/app';
import {
  useIsAppInBackground,
  useCacheSync,
  useOrientationListener,
  usePreventNavigationSwipes,
  useSyncPendingActions,
  useConnectionChecker,
  useExecutionErrorModal,
  useSyncBiEvents,
} from 'hooks';
import { OfflineBanner } from 'layouts/OfflineBanner/OfflineBanner';
import { getDeviceOrientation } from 'utils';
import { SettingsDrawer } from 'components';

export const testIds = {
  appLayout: 'app-layout',
};

export const AppLayout = () => {
  const { spyMount } = useSpy();
  const { distracting, concentrate } = useDistraction('settings');
  const [deviceOrientation, setDeviceOrientation] = useState<DeviceOrientation>(getDeviceOrientation());
  const { registerHermesHandlers, createNotificationSocket, setNavigationLock } = useAppStore([
    'registerHermesHandlers',
    'createNotificationSocket',
    'setNavigationLock',
  ]);
  useConnectionChecker();
  useCacheSync();
  useSyncPendingActions();
  useSyncBiEvents();
  useIsAppInBackground();
  useOrientationListener();
  usePreventNavigationSwipes();
  useExecutionErrorModal();

  useBlocker(() => {
    const { canNavigate } = appStore.getState();
    if (canNavigate) setNavigationLock(true);
    return !canNavigate;
  });

  useEffect(() => {
    createNotificationSocket();
    registerHermesHandlers();
    const handleResize = () => setDeviceOrientation(getDeviceOrientation());
    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);
  }, []);

  useEffect(() => spyMount(names.Screen.PageOrientation, deviceOrientation), [deviceOrientation]);

  return (
    <Flex w='100%' h='100%' direction='column' data-testid={testIds.appLayout}>
      <OfflineBanner />
      <Flex w='100%' mx='auto' role='main' bg='gray.0' className='grow overflow-hidden'>
        <Outlet />
      </Flex>
      <SettingsDrawer open={distracting} onClose={concentrate} />
    </Flex>
  );
};
