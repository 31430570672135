import i18next from 'i18next';
import { RangeValidation } from '@flow/flow-backend-types';
import { DeviceOrientation } from 'stores/app/app.types';
import { ExecutionStatus } from 'stores/flow';

export * from './date.utils';
export * from './image.utils';

export const BACK_PAGE_INDEX = -1; // BACK_PAGE_INDEX var

export const finishedExecutionStatuses: ExecutionStatus[] = ['done', 'expired', 'cancelled', 'pending'];

export function noop(..._args: any[]): any {}

type Map<T> = Record<string, T>;

export function arrayToMap<T>(array: T[], keyField: keyof T): Map<T> {
  return array.reduce<Map<T>>((acc, current) => {
    acc[current[keyField] as string] = current;
    return acc;
  }, {});
}

export function exists<T>(value: T | null | undefined): value is T {
  return value !== undefined && value !== null && !Number.isNaN(value);
}

export function parseNumberIfValid(value?: number | string | null): number | undefined {
  return exists(value) && value !== '' ? Number(value) : undefined;
}

export const getInitials = (str: string): string =>
  str
    .split(' ')
    .map((n) => n.charAt(0))
    .join('')
    .toUpperCase();

type GenericFunction = (...args: any[]) => any;

export function delay<T extends GenericFunction>(func: T, ms = 500, ...args: Parameters<T>): Promise<ReturnType<T>> {
  return new Promise((resolve) => {
    setTimeout(() => resolve(func(...args)), ms);
  });
}

export function getNumericRangeText(validation: RangeValidation) {
  const { min, max } = validation;
  if (exists(min) && exists(max)) return i18next.t('validation.between', { min, max });
  if (exists(min)) return i18next.t('validation.min', { min });
  if (exists(max)) return i18next.t('validation.max', { max });
  return '';
}

export function stopPropagation(event: React.MouseEvent) {
  event.stopPropagation();
}

export function redirectTo(route: string) {
  window.location.href = route;
}

export function normalizeToInteger(value: string | undefined) {
  return value ? Math.round(Number(value)) : undefined;
}

export function getDeviceOrientation() {
  const { Landscape, Portrait } = DeviceOrientation;
  const orientation = window.screen?.orientation?.type;
  if (orientation) return orientation.includes(Landscape) ? Landscape : Portrait;
  return window.innerWidth > window.innerHeight ? Landscape : Portrait;
}

export function capitalizeFirstLetter(str?: string) {
  return str && str[0].toUpperCase() + str.slice(1);
}

export async function safely<T, E = Error>(promise: Promise<T>): Promise<[E, undefined] | [null, T]> {
  try {
    return [null, await promise];
  } catch (error) {
    return [error as E, undefined];
  }
}
