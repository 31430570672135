import { Box, InputPlaceholder, ScrollArea, Stack, Text } from '@mantine/core';
import { CSSProperties, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Transcript } from '../VoiceBar.types';
import classes from './VoiceBarTranscript.module.css';

interface VoiceBarTranscriptProps {
  transcripts: Transcript[];
  style: CSSProperties;
}

export const testIds = {
  wrapper: 'voice-bar-transcript',
  placeholder: 'voice-bar-transcript-placeholder',
  getTranscriptTestId: (id: string) => `voice-bar-transcript-${id}`,
};

export const VoiceBarTranscript = ({ transcripts, style }: VoiceBarTranscriptProps) => {
  const { t } = useTranslation();
  const scrollAnchorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollAnchorRef.current) {
      scrollAnchorRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [transcripts]);

  return (
    <ScrollArea className={classes.wrapper} type='auto' style={style} onPointerDown={(e) => e.stopPropagation()}>
      <Stack gap='xl' data-testid={testIds.wrapper}>
        {Boolean(transcripts.at(0)?.transcript) || (
          <InputPlaceholder data-testid={testIds.placeholder}>{t('voice.transcript.placeholder')}</InputPlaceholder>
        )}
        {transcripts.map(({ id, transcript }) => (
          <Text key={id} className={classes.transcript} data-testid={testIds.getTranscriptTestId(id)}>
            {transcript}
          </Text>
        ))}
        <Box ref={scrollAnchorRef} component='span' />
      </Stack>
    </ScrollArea>
  );
};
