import { useEffect } from 'react';
import { Flex, Indicator, ScrollArea, Tabs, Highlight } from '@mantine/core';
import { ROUTES } from 'routes/routes.config';
import { PageWrapper, SkeletonList } from 'components';
import { names, useSpy } from 'services/espionage';
import { useFlowCollections, useFlowStore, useSingleInstanceValidator } from 'stores/flow';
import { useInspectionMetaStore } from 'stores/inspectionMeta';
import { useAppStore } from 'stores/app';
import { useForceNavigate } from 'hooks/useForceNavigate';
import { toaster } from 'services/toaster';
import { useTranslation } from 'react-i18next';
import { FlowList } from './components/FlowList/FlowList';
import { FlowNavbar } from './components/FlowNavbar/FlowNavbar';

export const testIds = {
  pageContainer: 'flow-page-container',
  tabs: 'flow-page-tabs-container',
  title: 'flow-page-title',
  getTabTestId: (id: string) => `flow-page-tab-${id}`,
  hasIndicator: (bool: boolean) => `flow-page-indicator-${bool}`,
};

const ERROR_TOAST_ID = 'flows-page-error-toast';

export const FlowsPage = () => {
  const { t } = useTranslation();
  const { isLoadingFlows, joinExecution, startExecution, setCurrentExecutionId, getFlowById } = useFlowStore([
    'isLoadingFlows',
    'joinExecution',
    'startExecution',
    'setCurrentExecutionId',
    'getFlowById',
  ]);
  const { currentFlowsTab, setCurrentFlowsTab } = useAppStore(['currentFlowsTab', 'setCurrentFlowsTab']);
  const { loadInspectionMeta } = useInspectionMetaStore(['loadInspectionMeta']);
  const flowCollections = useFlowCollections();
  const isSingleInstanceValid = useSingleInstanceValidator();
  const navigate = useForceNavigate();
  const { spyClick, spyPageview } = useSpy();

  useEffect(() => {
    spyPageview(names.FlowListPage.self);
    setCurrentExecutionId(undefined);
  }, []);

  const handleClickFlowItem = async (flowId: string, flowVersion: string) => {
    if (!isSingleInstanceValid(flowId)) {
      const { name } = getFlowById(flowId);
      toastSingleInstanceError(name);
      return;
    }
    const inspectionMeta = await loadInspectionMeta(flowId, flowVersion);
    const promptUserMetadata = !!inspectionMeta?.preInspectionFields?.length;

    if (promptUserMetadata) {
      navigate(ROUTES.PRE_INSPECTION(flowId));
    } else {
      const execution = await startExecution({
        flowId,
        version: flowVersion,
        preInspectionMetadata: [],
        uniqueIdentifier: '',
      });
      navigate(ROUTES.INSPECTION(execution?.id));
    }
  };

  const handleClickExecutionItem = async (executionId: string) => {
    const res = await joinExecution(executionId);
    if (res) navigate(ROUTES.INSPECTION(executionId));
  };

  const onClickByTab = (idx: number, title: string) => {
    spyClick(names.FlowListPage.Tab, title);
    setCurrentFlowsTab(idx);
  };

  const toastSingleInstanceError = (flowName: string) => {
    toaster.error({
      id: ERROR_TOAST_ID,
      title: t('flows.errors.singleInstance.title'),
      autoClose: false,
      message: (
        <Highlight
          size='md'
          highlight={flowName}
          highlightStyles={{
            fontWeight: 600,
            backgroundColor: 'unset',
          }}
        >
          {t('flows.errors.singleInstance.message', { name: flowName })}
        </Highlight>
      ),
    });
  };

  return (
    <PageWrapper data-testid={testIds.pageContainer}>
      <FlowNavbar />
      <Tabs
        value={flowCollections.at(currentFlowsTab)?.title}
        className='grow min-h-0 flex flex-col'
        data-testid={testIds.tabs}
      >
        <Tabs.List grow>
          {flowCollections.map(({ hasIntrigue, hasPendingExecution, isLoading, title }, idx) => (
            <Tabs.Tab
              key={title}
              value={title}
              onClick={() => onClickByTab(idx, title)}
              rightSection={
                <Indicator
                  color={hasPendingExecution || isLoading ? 'orange.6' : 'green.9'}
                  disabled={!hasIntrigue && !hasPendingExecution && !isLoading}
                  classNames={{ indicator: 'animate-pulse' }}
                  position='middle-end'
                  offset={-10}
                  data-testid={testIds.hasIndicator(hasIntrigue || hasPendingExecution || isLoading)}
                />
              }
              data-testid={testIds.getTabTestId(title)}
            >
              {title}
            </Tabs.Tab>
          ))}
        </Tabs.List>
        <Flex direction='column' h='95%'>
          {isLoadingFlows ? (
            <SkeletonList height={800} itemHeight={80} pt='sm' />
          ) : (
            flowCollections.map((collection) => (
              <ScrollArea key={collection.title} type='scroll'>
                <Tabs.Panel value={collection.title}>
                  <FlowList
                    collection={collection}
                    onClickFlowItem={handleClickFlowItem}
                    onClickExecutionItem={handleClickExecutionItem}
                  />
                </Tabs.Panel>
              </ScrollArea>
            ))
          )}
        </Flex>
      </Tabs>
    </PageWrapper>
  );
};
